<template>
  <div class="home-new">
    <img class="bg_img" src="../../../assets/images/ho_banner.png" alt="" />
    <div class="section_box">
      <div class="section_2 ">
        <div class="block_2">
          <!-- <span class="text_13">{{ langvalue.banner.one }}</span> -->
          <div class="text-group_1">
            <div class="text_14">{{ langvalue.banner.two }}</div>
            <span class="text_15">{{ langvalue.banner.three }}</span>
          </div>
          <div class="btn">{{ langvalue.banner.one }}</div>
          <!-- <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="~@/assets/images/jiantou.png"
        /> -->
        </div>
        <div class="image-wrapper_1">
          <!-- <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng24cfdf5fbe37868a1c8715dafeb64d817c3644589e3f3d4719a6d53ce93ca7dc"
        /> -->
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="../../../assets/images/wei.gif"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))
    const list = computed(() => {
      return store.state.category.list
    })

    // 跳转的时候不会关闭二级类目，通过数据来控制
    // 1. vuex每个分类加上open数据
    // 2. vuex提供显示和隐藏函数，修改open数据
    // 3. 组件中使用vuex中的函数，使用时间来绑定，提供一个类名显示隐藏的类名
    const show = item => {
      store.commit('category/show', item.id)
    }
    const hide = item => {
      store.commit('category/hide', item.id)
    }
    watch(
      () => store.state.home.type,
      newVal => {
        langvalue.value = returnLanguage(newVal)
      },
      { immediate: true }
    )
    return { list, show, hide, langvalue }
  }
}
</script>
<style scoped lang="less">
.home-new {
  height: 774px;
  box-sizing: border-box;
  position: relative;
  background: #f9f9f9;
  .section_box {
    padding-top: 120px;
    width: 1240px;
    margin: 0 auto;
  }
  .bg_img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
.section_2 {
  position: relative;
  z-index: 2;
  width: 1240px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // text-align: center;
  // padding-top: 120px;

  .block_2 {
    // width: 500px;
    // height: 900px;
    z-index: 2;

    .btn {
      width: 197px;
      height: 64px;
      color: #fff;
      border-radius: 50px;
      background: #100e34;
      text-align: center;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 64px; /* 54px */
      margin-top: 44px;
      margin-left: 12px;
    }

    .text_13 {
      width: 97px;
      height: 45px;
      overflow-wrap: break-word;
      color: rgba(17, 17, 17, 1);
      font-size: 32px;
      font-weight: NaN;
      text-align: center;
      white-space: nowrap;
      line-height: 45px;
      margin-left: 3px;
    }

    .text_14 {
      width: 500px;

      // overflow-wrap: break-word;
      color: rgba(17, 17, 17, 1);
      font-size: 80px;
      font-family: HarmonyOS_Sans_SC_Black;
      font-weight: NaN;
      text-align: left;
      line-height: 140px;
    }
    .text_15 {
      // border: 1px solid red;
      // width: 251px;
      height: 38px;
      overflow-wrap: break-word;
      color: #efa734;
      font-size: 24px;
      letter-spacing: 10px;
      text-transform: uppercase;
      font-family: PingFangSC-Semibold;
      font-weight: 400;
      text-align: left;
      line-height: 38px;
      margin-left: 12px;
    }
  }
  // .image_1 {
  //   // width: 2px;
  //   height: 111px;
  //   margin-bottom: 2px;
  // }
}

.image-wrapper_1 {
  background-color: rgba(255, 255, 255, 0.01);
  height: 580px;
  width: 580px;
  float: right;
  z-index: 1;
}

.image_2 {
  width: 660px;
  height: 617px;
  // margin: 41px 0 0 43px;
}
</style>
