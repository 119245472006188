<template>
  <div class="home-two">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.top.one }}</span>
      <span class="line"></span>
    </div>
    <div class="block_wrap">
      <div class="detail">
        <p>{{ langvalue.section1.one }}</p>
        <br />
        <p>{{ langvalue.section1.two }}</p>
        <br />
        <p>{{ langvalue.section1.three }}</p>
        <!-- <div class="btn" @click="goAboutInfo">
          <img src="../../../assets/images/more_btn.png" alt="" />
        </div> -->
      </div>
      <div class="abimglist">
        <div class="avimgitem">
          <img src="https://resources.wukongbc.com/img/banner/1.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'
import router from '@/router'
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    const type = ref(langtype)
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))

    const goAboutInfo = () => {
      window.localStorage.setItem('mao', '#m0')
      router.push('/about')
    }
    watch(
      () => store.state.home.type,
      newVal => {
        langvalue.value = returnLanguage(newVal)
        type.value = newVal
      },
      { immediate: true }
    )
    return { langvalue, type, goAboutInfo }
  }
}
</script>
<style scoped lang="less">
.home-two {
  padding: 133px 0;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  box-sizing: border-box;

  .title {
    margin: 0 auto 88px;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .line {
      display: inline-block;
      width: 100px;
      height: 2px;
      background: #d3d5e0;
    }
    .text {
      margin: 0 10px;
    }
  }
  .block_wrap {
    width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0 0;
    .detail {
      width: 697px;
      font-size: 28px;
      font-weight: 300;
      color: #000;
      font-weight: 400;
      // 左对齐
      text-align: left;
      .btn {
        margin-top: 30px;
        width: 172px;
        height: 55px;
      }
    }
    .abimglist {
      .avimgitem {
        width: 630px;
        height: 430px;
        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
