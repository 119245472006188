<template>
  <div class="home-nine">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.top.six }}</span>
      <span class="line"></span>
    </div>
    <div class="detail">
      <div class="main">
        <div class="content_t">
          <div class="t_content">
            <div class="left _item">
              <div class="img_box">
                <img :src="'https://admin.wukongbc.com'+left.thumb" alt="" />
              </div>
              <div class="info_box">
                <div class="cstitle">{{ left.title }}</div>

                <div class="stitle">
                  <div class="s_title">
                    <img src="../../../assets/images/Star.png" />
                    {{ left.desc ? left.desc[0].split('：')[0] : ''  }}
                  </div>
                  <p class="num">
                    {{ left.desc ? left.desc[0].split('：')[1] : '' }}
                  </p>
                </div>
                <div class="s_center">
                  <p class="s_line"></p>
                </div>

                <div class="stitle">
                  <div class="s_title">
                    <img src="../../../assets/images/Star.png" />
                    {{ left.desc ? left.desc[1].split('：')[0] : '' }}
                  </div>
                  <p class="num">
                    {{ left.desc ? left.desc[1].split('：')[1] : '' }}
                  </p>
                </div>
              </div>
            </div>
            <div class="right _item">
              <div class="img_box">
                <img :src="'https://admin.wukongbc.com'+right.thumb" alt="" />
              </div>
              <div class="info_box">
                <div class="cstitle">{{ right.title }}</div>
                <div class="stitle">
                  <div class="s_title">
                    <img src="../../../assets/images/Star.png" />
                    {{ right.desc ? right.desc[0].split('：')[0] : '' }}
                  </div>
                  <p class="num">
                    {{ right.desc ? right.desc[0].split('：')[1] : '' }}
                  </p>
                </div>
                <div class="s_center">
                  <p class="s_line"></p>
                </div>
                <div class="stitle">
                  <div class="s_title">
                    <img src="../../../assets/images/Star.png" />
                    {{ right.desc ? right.desc[1].split('：')[0] : '' }}
                  </div>
                  <p class="num">
                    {{ right.desc ? right.desc[1].split('：')[1] : '' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="foot">
            <div class="more" @click="goMore">
              {{ langvalue.top.more }}
              <img src="../../../assets/images/arrow-right.png" alt="" />
            </div>
          </div>
        </div>
        <!-- <div class="content">
          <div class="banner">
            <ul>
              <li>
                <div class="abimglist">
                  <div class="avimgitem">
                    <div class="stitle"></div>
                    <img
                      src="https://resources.wukongbc.com/goods/goods1.png"
                    />
                    <div class="content">
                      <div class="cstitle">Bitcoin Miner S21</div>
                      <div class="stitle">
                        {{ langvalue.goods.four }} 200T | 3500W | 17.5J/T
                      </div>
                      <div class="stitle">
                        {{ langvalue.goods.one }} BTC/BCH/BSV SHA256
                        {{ langvalue.goods.two }}
                      </div>
                    </div>
                  </div>
                  <div class="avimgitem">
                    <div class="stitle"></div>
                    <img
                      src="https://resources.wukongbc.com/goods/goods2.png"
                    />
                    <div class="content">
                      <div class="cstitle">Bitcoin Miner S21 Hyd.</div>
                      <div class="stitle">
                        {{ langvalue.goods.four }} 335T | 5360W | 16.0J/T
                      </div>
                      <div class="stitle">
                        {{ langvalue.goods.one }} BTC/BCH/BSV SHA256
                        {{ langvalue.goods.three }}
                      </div>
                    </div>
                  </div>
                  <div class="avimgitem">
                    <div class="stitle"></div>
                    <img
                      src="https://resources.wukongbc.com/goods/goods3.png"
                    />
                    <div class="content">
                      <div class="cstitle">Bitcoin Miner S19k Pro</div>
                      <div class="stitle">
                        {{ langvalue.goods.four }} 120T | 2760W | 23J/T
                      </div>
                      <div class="stitle">
                        {{ langvalue.goods.one }} BTC/BCH/BSV SHA256
                        {{ langvalue.goods.two }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="abimglist">
                  <div class="avimgitem">
                    <div class="stitle"></div>
                    <img
                      src="https://resources.wukongbc.com/goods/goods4.png"
                    />
                    <div class="content">
                      <div class="cstitle">Bitcoin Miner T21</div>
                      <div class="stitle">
                        {{ langvalue.goods.four }} 190T | 3610W | 19.0J/T
                      </div>
                      <div class="stitle">
                        {{ langvalue.goods.one }} BTC/BCH/BSV SHA256
                        {{ langvalue.goods.two }}
                      </div>
                    </div>
                  </div>
                  <div class="avimgitem">
                    <div class="stitle"></div>
                    <img
                      src="https://resources.wukongbc.com/goods/goods5.png"
                    />
                    <div class="content">
                      <div class="cstitle">Bitcoin Miner S19 XP Hyd.</div>
                      <div class="stitle">
                        {{ langvalue.goods.four }} 257T | 5345.6W | 20.8J/T
                      </div>
                      <div class="stitle">
                        {{ langvalue.goods.one }} BTC/BCH/BSV SHA256
                        {{ langvalue.goods.three }}
                      </div>
                    </div>
                  </div>
                  <div class="avimgitem">
                    <div class="stitle"></div>
                    <img
                      src="https://resources.wukongbc.com/goods/goods6.png"
                    />
                    <div class="content">
                      <div class="cstitle">Bitcoin Miner S19j XP</div>
                      <div class="stitle">
                        {{ langvalue.goods.four }} 151T | 3247W | 21.5J/T
                      </div>
                      <div class="stitle">
                        {{ langvalue.goods.one }} BTC/BCH/BSV SHA256
                        {{ langvalue.goods.two }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="abimglist">
                  <div class="avimgitem">
                    <div class="stitle"></div>
                    <img
                      src="https://resources.wukongbc.com/goods/goods7.png"
                    />
                    <div class="content">
                      <div class="cstitle">Bitcoin Miner S19 Pro Hyd</div>
                      <div class="stitle">
                        {{ langvalue.goods.four }} 184T | 5428W | 29.5J/T
                      </div>
                      <div class="stitle">
                        {{ langvalue.goods.one }} BTC/BCH/BSV SHA256
                        {{ langvalue.goods.three }}
                      </div>
                    </div>
                  </div>
                  <div class="avimgitem">
                    <div class="stitle"></div>
                    <img
                      src="https://resources.wukongbc.com/goods/goods8.png"
                    />
                    <div class="content">
                      <div class="cstitle">Bitcoin Miner S19 XP</div>
                      <div class="stitle">
                        {{ langvalue.goods.four }} 141T | 3031.5W | 21.5J/T
                      </div>
                      <div class="stitle">
                        {{ langvalue.goods.one }} BTC/BCH/BSV SHA256
                        {{ langvalue.goods.two }}
                      </div>
                    </div>
                  </div>
                  <div class="avimgitem">
                    <div class="stitle"></div>
                    <img
                      src="https://resources.wukongbc.com/goods/goods9.png"
                    />
                    <div class="content">
                      <div class="cstitle">Bitcoin Miner S19 Hyd</div>
                      <div class="stitle">
                        {{ langvalue.goods.four }} 151.5T | 5226W | 34.5J/T
                      </div>
                      <div class="stitle">
                        {{ langvalue.goods.one }} BTC/BCH/BSV SHA256
                        {{ langvalue.goods.three }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="forward">
              <a href="javascript:;"
                ><svg
                  style="width:40px"
                  t="1699791397392"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2451"
                  width="128"
                  height="128"
                >
                  <path
                    d="M512 128c212 0 384 172 384 384s-172 384-384 384-384-172-384-384 172-384 384-384m0-64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64zM398.4 240l-45.6 45.6L580 512 353.6 738.4l45.6 45.6 272-272-272.8-272z"
                    p-id="2452"
                  ></path></svg></a>
            </div>
            <div class="back">
              <a href="javascript:;"
                ><svg
                  style="width:40px"
                  t="1699791342235"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="1468"
                  width="128"
                  height="128"
                >
                  <path
                    d="M512 64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z m0 832c-212 0-384-172-384-384s172-384 384-384 384 172 384 384-172 384-384 384z m158.4-610.4L444 512l226.4 226.4-44.8 45.6-272-272 272-272 44.8 45.6z"
                    p-id="1469"
                  ></path></svg></a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'
import router from '@/router'
import { getslList } from '@/api/new'
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))
    const checkedtype = ref(langvalue.value.section4_3_1.one)
    const type = ref(langtype)
    const checktype = type => {
      checkedtype.value = type
    }
    // console.log(langvalue.value, '超算页面')
    const tabList = ref(['one', 'two', 'three', 'four'])
    let currentIndex = ref(0)
    const animate = (obj, target, callback) => {
      // console.log(callback);  callback = function() {}  调用的时候 callback()

      // 先清除以前的定时器，只保留当前的一个定时器执行
      clearInterval(obj.timer)
      obj.timer = setInterval(function() {
        // 步长值写到定时器的里面
        // 把我们步长值改为整数 不要出现小数的问题
        // var step = Math.ceil((target - obj.offsetLeft) / 10);
        var step = (target - obj.offsetLeft) / 10
        step = step > 0 ? Math.ceil(step) : Math.floor(step)
        if (obj.offsetLeft == target) {
          // 停止动画 本质是停止定时器
          clearInterval(obj.timer)
          // 回调函数写到定时器结束里面
          // if (callback) {
          //     // 调用函数
          //     callback();
          // }
          callback && callback()
        }
        // 把每次加1 这个步长值改为一个慢慢变小的值  步长公式：(目标值 - 现在的位置) / 10
        obj.style.left = obj.offsetLeft + step + 'px'
      }, 15)
    }

    let left = ref({})
    let right = ref({})
    const getNewList = () => {
      getslList().then(res => {
        let { data } = res

        // console.log('data',data)
        left.value = data[0]
        right.value = data[1]
        // console.log(productList)
      })
    }
    getNewList()
    /* 选中tab切换 */
    const checkedTab = index => {
      currentIndex.value = index
    }
    /* 去到更多 */
    const goMore = () => {
      window.localStorage.setItem('mao', '#m8')
      router.push('/csList')
    }

    // onMounted(() => {
    //   // 1、获取元素对象
    //   var forward = document.querySelector('.forward')
    //   var back = document.querySelector('.back')
    //   var ul = document.querySelector('.banner').querySelector('ul')
    //   var firstli = ul.children[0]
    //   // 2、把ul第一个li复制一份，放到ul的最后面
    //   // eslint-disable-next-line camelcase
    //   var firstli_clone = firstli.cloneNode(true)
    //   ul.appendChild(firstli_clone)
    //   console.log(firstli)
    //   // 3、声明一个变量i
    //   var i = 0
    //   // 图片的宽度(=包含图片小li的宽度)
    //   // eslint-disable-next-line camelcase
    //   var img_width = document.querySelector('.banner').querySelector('li')
    //     .offsetWidth
    //   // 4、右侧按钮
    //   forward.addEventListener('click', function() {
    //     // eslint-disable-next-line eqeqeq
    //     if (i == ul.children.length - 1) {
    //       ul.style.left = 0 + 'px'
    //       i = 0
    //     }
    //     i++
    //     // diaplacement表ul需要移动的距离
    //     // eslint-disable-next-line camelcase
    //     var diaplacement = -img_width * i
    //     // eslint-disable-next-line no-undef
    //     animate(ul, diaplacement)
    //   })
    //   // 5、左侧按钮
    //   back.addEventListener('click', function() {
    //     // eslint-disable-next-line eqeqeq
    //     if (i == 0) {
    //       // eslint-disable-next-line camelcase
    //       ul.style.left = -img_width * (ul.children.length - 1) + 'px'
    //       i = ul.children.length - 1
    //     }
    //     i--
    //     // diaplacement表ul需要移动的距离
    //     // eslint-disable-next-line camelcase
    //     var diaplacement = -img_width * i
    //     // eslint-disable-next-line no-undef
    //     animate(ul, diaplacement)
    //   })
    // })
    watch(
      () => store.state.home.type,
      newVal => {
        langvalue.value = returnLanguage(newVal)
        type.value = newVal
        getNewList()
      },
      { immediate: true }
    )
    return {
      langvalue,
      checkedtype,
      checktype,
      type,
      tabList,
      currentIndex,
      checkedTab,
      goMore,
      left,
      right
    }
  }
}
</script>
<style scoped lang="less">
.home-nine {
  // width: 1240px;
  background: #eaebf0;
  margin: 0 auto;
  padding: 90px 0 83px;
}
.title {
  font-size: 40px;
  // font-weight: bold;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }
  .text {
    margin: 0 10px;
  }
}
.detail {
  width: 1300px;
  font-size: 20px;
  font-weight: 300;
  margin: 20px auto;
  display: flex;
  // flex-wrap: wrap;
  // border-radius: 32px;
  // background-image: linear-gradient(
  //   180deg,
  //   rgba(217, 255, 253, 1),
  //   rgba(159, 177, 255, 1)
  // );
  .main {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px auto;
    // background: white;
    // border-radius: 32px;
    .tabx_box {
      display: flex;
      justify-content: center;

      .tabs {
        width: 642px;
        display: flex;
        justify-content: space-between;
        .t_itmes {
          font-size: 20px;
          color: #666872;
          width: 154px;
          height: 56px;
          line-height: 56px;

          border-radius: 88px;
          text-align: center;
          &.active {
            background: #444242;
            color: #fff;
          }
        }
      }
    }
    .menutop {
      width: 100%;
      display: flex;
      padding: 20px;
      .menuitem {
        width: 33.3%;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        .checked {
          border-bottom: 2px solid black;
          padding-bottom: 12px;
        }
      }
    }

    .content_t {
      margin-top: 154px;
      .t_content {
        display: flex;
        justify-content: space-between;

        ._item {
          position: relative;
          padding-left: 236px;

          .img_box {
            position: absolute;
            background: linear-gradient(
              180deg,
              #9abfed 0%,
              rgba(255, 255, 255, 0) 100%
            );
            width: 280px;
            height: 280px;
            border-radius: 8px;
            z-index: 1;
            left: 0;
            top: 146px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 180px;
              height: 180px;
            }
          }
          .info_box {
            padding: 43px 25px 101px;
            background: #fff;
            border-radius: 15px;
            .cstitle {
              text-align: center;
              color: #100e34;
              font-size: 35px;
              font-weight: 400;
              margin-bottom: 56px;
            }
            .s_center {
              padding: 31px 0 19px;
              p.s_line {
                width: 282px;
                height: 1px;
                background: #e2e2e2;
              }
            }
            .stitle {
              padding-left: 45px;
              .s_title {
                display: flex;
                align-items: center;
                color: #666872;
                font-size: 25px;
                font-weight: 600;
                img {
                  width: 16px;
                  height: 16px;
                  margin-right: 20px;
                }
              }
              .num {
                margin: 11px 0 0 36px;
                color: #000;
                font-size: 20px;
                font-weight: 400;
                width: 239px;
              }
            }
          }
        }
        .right._item {
          .s_center {
            padding: 31px 31px 19px;
          }
        }
      }

      .foot {
        display: flex;
        justify-content: flex-end;
        .more {
          margin-top: 114px;
          padding: 12px 0;
          border-bottom: 1px solid #000;
          color: #000;
          font-size: 20px;
          font-weight: 600;
          cursor: pointer;
          img {
            width: 24px;
            height: 24px;
            margin-left: 20px;
          }
        }
      }
    }

    /* 这个就不要了 */
    .content {
      padding: 20px;
      overflow: hidden;
      a {
        text-decoration: none;
        color: #000000;
        font-size: 24px;
      }
      .banner {
        position: relative;
        width: 1160px;
        margin: 0 auto;
        overflow: hidden;
        .abimglist {
          display: flex;
          justify-content: space-between;
          .avimgitem {
            padding: 0 20px;
            width: 33.3%;
            img {
              width: calc(100% - 150px);
              height: 186px;
              margin: 20px;
            }

            .stitle {
              font-size: 18px;
              margin: 10px 0;
            }
            .content {
              padding: 0px;
              border-radius: 20px;
              background-color: rgb(249, 252, 255);
              font-size: 16px;
              .cstitle {
                background: #000000;
                color: white;
                font-size: 18px;
              }
              .stitle {
                font-size: 16px;
                margin: 10px 0;
                // 文字居左
                text-align: left;
              }
            }
          }
        }
      }
      .banner ul {
        position: relative;
        width: 6000px;
        height: 398px;
        background-color: darkslategray;
      }
      li {
        float: left;
        width: 1160px;
        height: 398px;
        overflow: hidden;
        list-style: none;
        background-color: white;
      }
      .forward {
        position: absolute;
        right: 0;
        top: 120px;
      }
      .back {
        position: absolute;
        left: 0;
        top: 120px;
      }
    }
  }
}
</style>
